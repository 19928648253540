import { ToasterTexts } from '../components/shared/constants';
import { ToasterType } from '../components/shared/toaster/toaster.model';
import Config from "../config";
import { AxiosInstance } from "../helpers/AxiosInstance";
import setToast from '../helpers/toaster.helper';
import { mapToAutoAlertKeyword, mapToAutoAlertRule } from '../models/auto-alert.model';
import { ChannelMessageItem, Contact, ContactType, ConversationModel, mapToChannelContactItem, mapToChannelConversationItem, mapToContact, mapToContactType, mapToConverstion, mapToGroup, mapToUser, OutLookResponse } from "../models/conversations/index";
import { mapToFeature } from '../models/features.model';
import { KafkaModel, mapToKafka } from '../models/kafka.model';
import { mapToOrganization } from "../models/organization.model";
import { mapToPhoneNumber, mapToProvider, PhoneNumberModel } from '../models/phone-number.model';
import { PlanMetrics, mapToPlanMetrics } from '../models/plan-metrics.model';
import { mapToReport } from '../models/reports.model';
import { mapToRole } from "../models/roles.model";
import { mapToSetting } from "../models/settings-model";
import { ChannelTemplates, mapToChannelTemplateModel, mapToFaceBookTemplateModel, mapToTemplate, TemplateModel } from '../models/templates.models';
import { mapToPlanHistory } from '../models/transaction-history.model';
import { cleanHistory, clearHistory, clearToast, groupContactsDeletion, handlePushGroupChatName, hideToast, LoadingChannels, loadingKafkaOrg, loadingReports, loadingSecureFiles, loadingSecureFilesLinks, LoadMsgLogsExports, pushConversations, pushMessageLogsDownloadReport, setKafka, setShowingAlert, syncingContacts, updateActiveChannelsTypes, updateAlertNotification, updateBlockedContacts, updateChannelConversations, updateChannelHistory, updateChannels, updateChannelTemplates, updateChannelUsers, updateContact, updateContacts, updateContactsInConversation, updateContactTypes, updateContactTypesInConversation, updateConversations, updateFaceboolTemplates, updateFeatures, updateGlobalAutomationRules, updateGroups, updateHistory, updateKafka, updateKeywords, updateLoadingAlertNotification, updateLoadingConnectConversations, updateLoadingContacts, updateLoadingConversations, updateLoadingFeatures, updateLoadingGlobalAutomationRules, updateLoadingGroups, updateLoadingKeywords, updateLoadingOrganizations, updateLoadingPhoneNumbers, updateLoadingPlanes, updateLoadingPrivateAutomationRules, updateLoadingProviders, updateLoadingTemplates, updateLoadingUsers, updateMassContactsDeletionInProgess, updateMassGroupsDeletionInProgess, updateMenuFlag, updateNewMessageRecived, updateOrganizations, updateOrgName, updatePhoenNumbers, updatePlanHistory, updatePlanMetrics, updatePlans, updatePrivateAutomationRules, updateProviders, updateReports, updateRoles, updateSecureFiles, updateSecureFilesLinks, updateSettings, updateTemplates, updateUnsubscribedContacts, updateUserAlertNotifaction, updateUserRoleId, updateUsers, updateAllChannelOrganizations, updateAllChannelUsers, updateChannelContacts, updateWordPressDetails } from '../reducers/reducer';
// import { clearToast, hideToast } from '../reducers/reducer';
import { AutoAlertRulesService } from '../services/AutoAlertRulesService';
import { ContactService } from "../services/ContactService";
import { FeatureService } from '../services/FeatureService';
import { GroupContactService } from "../services/GroupContactService";
import { KafkaService } from '../services/KakfaServies';
import { MessageService } from '../services/MessageService';
import { OrganizationService } from "../services/OrganizationService";
import { PhoneNumberService } from '../services/PhonenumberService';
import { PlansService } from "../services/plans.service";
import { ReportService } from '../services/ReportService';
import { SettingsService } from "../services/SettingsService";
import { TemplateService } from '../services/TemplateService';
import { UserService } from "../services/UserService";
import { UtilityService } from '../services/UtilityService';
import { mapToAlertNotification } from './../models/alert.model';
import { mapToPlan } from './../models/plans.model';
import { PlanHistoryModel } from './../models/transaction-history.model';
import { AlertNotificationService } from './../services/AlertNotificationService';
import { mapToSecureFileNameWithLinksModel, mapToSecureFiles } from '../models/secure-file';
import { SecureFileService } from '../services/SecureFileService';
import { MessageLogSevice } from '../services/messageLogService';
import { mapToMessageLogExportModel } from '../models/msg.export.model';
import { ChannelConversationService } from '../services/channels/ChannelConversationService';
import { ChannelService } from '../services/channels/ChannelAcountService';
import { ChannelAccountModel, ChannelType, mapToChannelGrid, mapToChannelOrganization } from '../models/channels/channels.model';
import { ChannelUserService } from '../services/ChannelUserService';
import { mapToChannelUser } from '../models/users.model';
import { ProviderService } from '../services/channels/ChannelProviderService';
import { ChannelContactServices } from '../services/channels/ChannelContactService';

export const SetUserRoleId = (dispatch: any, id: number) => {
    dispatch(updateUserRoleId(id))
}

export const LoadMessages = (dispatch: any) => {
    dispatch(updateLoadingConversations(true))
    AxiosInstance.get(`${Config.API_URL}/api/Messages`).then(
        res => {
            dispatch(updateConversations(res.data.map(mapToConverstion)))
        }
    )
}

export const LoadContactTypes = (dispatch: any): void => {
    AxiosInstance.get(`${Config.API_URL}/api/ContactTypes`).then(
        res => {
            let data = res.data.map(mapToContactType);
            dispatch(updateContactTypes(data))
            dispatch(updateContactTypesInConversation(data))
        }
    )
}

export const UpdateHistory = (dispatch: any, phoneNumber: string, history: ConversationModel[]) => {
    dispatch(updateHistory({ phoneNumber, history }))
}

export const LoadContacts = (dispatch: any) => {
    dispatch(updateLoadingContacts(true))
    AxiosInstance.get(`${Config.API_URL}/api/Contacts`).then(
        res => {
            let data = res.data?.map(mapToContact)
            dispatch(updateContacts(data))
            dispatch(updateContactTypesInConversation(data))
            dispatch(updateContactsInConversation(data))
        }
    )
}

export const HandlePushConversation = (dispatch: any, data: ConversationModel[]) => {

    dispatch(pushConversations(data));
    data.forEach(conversation => {
        if (conversation.Message?.toLowerCase() === "stop" && !conversation.IsOutbound) {
            setToast(`${conversation.Contact?.Name || conversation.MaskedPhoneNumber} ${ToasterTexts.UNSUBSCRIBE_INFO}`, ToasterType.INFO)
        }
        if (conversation.Message?.toLowerCase() === "unstop" && !conversation.IsOutbound) {
            setToast(`${conversation.Contact?.Name || conversation.MaskedPhoneNumber} ${ToasterTexts.RESUBSCRIBE_INFO}`, ToasterType.INFO)
        }
        // if (conversation.DeliveryStatus === DeliveryStatus.SENT) {

        // }
        // if (conversation.DeliveryStatus === DeliveryStatus.FAILED ) {
        //     setToast(ToasterTexts.MESSAGE_SEND_FAIL.replace('<Name>', (conversation.Contact?.Name || conversation.MaskedPhoneNumber)), ToasterType.DANGER)
        // }
    })

}

export const LoadUsers = (dispatch: any) => {
    dispatch(updateLoadingUsers(true));
    UserService.getUsers().then(res => {
        dispatch(updateUsers(res.data.map(mapToUser)))
    }
    )
}
export const LoadGroups = (dispatch: any) => {
    dispatch(updateLoadingGroups(true));
    GroupContactService.getGroups().then(res => {
        dispatch(updateGroups(res.data.map(mapToGroup)))
    }
    )
}

export const LoadBlockedContacts = (dispatch: any) => {
    ContactService.getBlockedContacts().then((res) => {
        dispatch(updateBlockedContacts(res.data.map(mapToContact)))
    });
}

export const LoadUnSubcribedContacts = (dispatch: any) => {
    ContactService.getUnsubscribeContacts().then((res) => {
        dispatch(updateUnsubscribedContacts(res.data.map(mapToContact)))
    })
}

export const UpdatePlanMetrics = (dispatch: any, data: PlanMetrics) => {
    dispatch(updatePlanMetrics(mapToPlanMetrics(data)));
}

// export const LoadProfile = (dispatch: any) => {
//     dispatch(updateLoadingProfile(true))
//     return UserService.getCurrentUser(fromApi).then((res: any) => {
//         dispatch(updateProfile(mapToProfile(res.data)))
//         dispatch(updatePlanMetrics(mapToPlanMetrics(res.data.planTracker)))
//         return res;
//     }
//     )
// }

// export const UpdateProfile = (dispatch: any, profile: any) => {
//     dispatch(updateProfile(mapToProfile(profile)))
// }

export const LoadOrgName = (dispatch: any, id: string) => {
    OrganizationService.getOrganizationById(id).then((res) => {
        dispatch(updateOrgName(typeof res.data === "object" && res.data >= 0 ? res.data[0].name : res.data.name))
    })
}

export const UpdateContact = (dispatch: any, Contact: Contact) => {
    dispatch(updateContact(Contact))
}

export const UpdateContactTypes = (dispatch: any, data: ContactType[]): void => {
    dispatch(updateContactTypes(data))
    dispatch(updateContactTypesInConversation(data))
}

export const UpdateSettings = (dispatch: any, setting?: any): Promise<any> => {
    return SettingsService.updateSetting({ ...setting }).then(res => {
        dispatch(updateSettings(res.map(mapToSetting)))
    })
}

export const LoadSettings = (dispatch: any) => {
    return SettingsService.getAllSettings().then((res: any) => {
        dispatch(updateSettings(res.map(mapToSetting)))
    })
}

export const UpdateMenuFlag = (dispatch: any, flag: boolean) => {
    dispatch(updateMenuFlag(flag))
}


export const UpdateContactsMassActionDeletionStatus = (dispatch: any, flag: boolean) => {
    dispatch(updateMassContactsDeletionInProgess(flag))
}

export const UpdateContactsGroupsMassActionDeletionStatus = (dispatch: any, flag: boolean) => {
    dispatch(updateMassGroupsDeletionInProgess(flag))
}

export const ClearConversationHistory = (dispatch: any) => {
    dispatch(clearHistory)
}
export const CleanConversationHistory = (dispatch: any, number: string) => {
    dispatch(cleanHistory(number))
}

export const LoadOrganizations = (dispatch: any) => {
    dispatch(updateLoadingOrganizations(true))
    OrganizationService.getOrganizationsGridData().then(res => {
        dispatch(updateOrganizations(res.data.map(mapToOrganization)))
    })
}

export const LoadMessageLogsExport = (dispatch: any) => {
    MessageLogSevice.getExportedMesageLogs().then(res => {
        dispatch(LoadMsgLogsExports(res.data.map(mapToMessageLogExportModel)))
    })
}

export const ResetMessageLogsExportMsg = (dispatch: any) => {
    MessageLogSevice.getExportedMesageLogs().then(res => {
        dispatch(pushMessageLogsDownloadReport(""))
    })
}

export const getOrgSettings = (dispatch: any, id: string) => {
    SettingsService.getOrganizationSettings(id).then((res) => {
        dispatch(updateSettings(res.data.map(mapToSetting)))
    })
}

export const LoadFeatures = (dispatch: any) => {
    dispatch(updateLoadingFeatures(true))
    FeatureService.getAllFeature().then((res: any) => {
        dispatch(updateFeatures(res.data.map(mapToFeature)))
    })
}

export const LoadPhoneNumbers = (dispatch: any) => {
    dispatch(updateLoadingPhoneNumbers(true))
    return PhoneNumberService.getPhoneNumbersGridData().then((res: any) => {
        dispatch(updatePhoenNumbers(res.data.map(mapToPhoneNumber).filter((x: PhoneNumberModel) => UtilityService.validPhoneNumber(x.PhoneNumber))))
        return res;
    })
}

export const getRoles = (dispatch: any) => {
    UserService.getRoles().then((res: any) => {
        dispatch(updateRoles(res.data.map(mapToRole)))
    })
}
export const UpdateOrgSettings = (dispatch: any, id: string, setting: any, toasterMsg: string) => {
    SettingsService.UpdateOrganizationSettings(id, [{ ...setting }]).then(res => {
        dispatch(updateSettings(res.data.map(mapToSetting)))
        setToast(toasterMsg, ToasterType.SUCCESS)
    })
}

export const ClearToast = (dispatch: any, id: string) => {
    dispatch(hideToast({ id }))
    setTimeout(() =>
        dispatch(clearToast({ id }))
        , 500);
}

export const LoadPlans = (dispatch: any) => {
    dispatch(updateLoadingPlanes(true))
    return PlansService.getPlans().then((res: any) => {
        dispatch(updatePlans(res.data.map(mapToPlan)))
        return res;
    })
}

export const LoadProviders = (dispatch: any) => {
    dispatch(updateLoadingProviders(true))
    return PhoneNumberService.getProviders().then((res: any) => {
        dispatch(updateProviders(res.data.map(mapToProvider)))
        return res;
    })
}

export const MuteConversation = (dispatch: any, phonenumber: string, duration: number) => {
    return MessageService.muteConversation(phonenumber, duration).then((res: any) => {
        let data = res.map(mapToContactType);
        dispatch(updateContactTypes(data))
        dispatch(updateContactTypesInConversation(data))
        return res;
    })
}

export const LoadAlertNotifications = (dispatch: any) => {
    dispatch(updateLoadingAlertNotification(true))
    AlertNotificationService.getAllSystemAdminAlertNotifications().then(res =>
        dispatch(updateAlertNotification(res.data.map(mapToAlertNotification)))
    )
}

export const LoadUserAlertNotifications = (dispatch: any) => {
    AlertNotificationService.getAllUserAlertNotifications().then(res =>
        dispatch(updateUserAlertNotifaction(res.data.map(mapToAlertNotification)))
    )
}

export const SetAlertFlag = (dispatch: any, count: number) => {
    dispatch(setShowingAlert(count))
}

export const loadAutoAlertKeywords = (dispatch: any) => {
    dispatch(updateLoadingKeywords(true))
    AutoAlertRulesService.getAutoAlertKeywords().then(res =>
        dispatch(updateKeywords(res.data.map(mapToAutoAlertKeyword)))
    )
}

export const LoadTemplates = (dispatch: any) => {
    dispatch(updateLoadingTemplates(true));
    TemplateService.getTemplates().then(res => {
        dispatch(updateTemplates(res.data.map(mapToTemplate)?.sort((x: TemplateModel, y: TemplateModel) => new Date(x.UpdatedDateTime) <= new Date(y.UpdatedDateTime) ? 1 : -1)))
    })
}

export const LoadKafkaOrg = (dispatch: any) => {
    dispatch(loadingKafkaOrg(true))
    KafkaService.getKafkaOrganiztons().then(res => {
        dispatch(setKafka(res.data?.map(mapToKafka)))
    })
}
export const syncContacts = (dispatch: any, data: OutLookResponse) => {
    dispatch(syncingContacts(data))
}

export const UpdateKafkaOrg = (dispatch: any, data: KafkaModel) => {
    dispatch(updateKafka(data))
}

export const loadPrivateAutomationRules = (dispatch: any) => {
    dispatch(updateLoadingPrivateAutomationRules(true))
    return AutoAlertRulesService.getAutoAlertPrivateRules().then(res =>
        dispatch(updatePrivateAutomationRules(res.data.map(mapToAutoAlertRule)))
    )
}
export const loadGlobalAutomationRules = (dispatch: any) => {
    dispatch(updateLoadingGlobalAutomationRules(true))
    return AutoAlertRulesService.getAutoAlertGlobalRules().then(res =>
        dispatch(updateGlobalAutomationRules(res.data.map(mapToAutoAlertRule)))
    )
}

export const LoadReports = (dispatch: any, page: number, pageSize: number) => {
    dispatch(loadingReports(true))
    ReportService.getReports(page, pageSize).then(res => {
        dispatch(updateReports(res.data.map(mapToReport)))
    })
}


export const LoadPlanHistory = (dispatch: any, phoneID: string) => {
    PhoneNumberService.RechargeHistory(phoneID).then((res) => {
        dispatch(updatePlanHistory(res.data.map(mapToPlanHistory)?.filter((x: PlanHistoryModel) => x)))
    })
}
export const LoadGroupContactsDeletion = (dispatch: any, data: any) => {
    dispatch(groupContactsDeletion(data))
}

export const LoadSecureFiles = (dispatch: any, id: any) => {
    dispatch(loadingSecureFiles(true)
    )
    SecureFileService.getAllSecureFiles(id).then(res => {
        dispatch(updateSecureFiles(res.data.map(mapToSecureFiles)))
    })
}

export const LoadSecureFileLinks = (dispatch: any) => {
    let id = { "user_id": UserService.getCurrentUserId() }
    dispatch(loadingSecureFilesLinks(true))
    SecureFileService.getAllSecureFileNameAndLinks(id).then(res => {
        dispatch(updateSecureFilesLinks(res.data.map(mapToSecureFileNameWithLinksModel)))
    })
}

export const UpdateNewMessageRecived = (dispatch: any, Message: ConversationModel[]) => {
    dispatch(updateNewMessageRecived({ Message })
    )
}

export const PushMessageLogsDownloadReport = (dispatch: any, msg: string) => {
    dispatch(pushMessageLogsDownloadReport(msg)
    )
}
export const HandlePushGroupChatName = (dispatch: any, id: string, name: string) => {
    dispatch(handlePushGroupChatName({ id, name }))
}

//channels

export const LoadConnectMessages = (dispatch: any, orgID: string) => {
    dispatch(updateLoadingConnectConversations(true))
    ChannelConversationService.getAllParticipants(orgID).then(
        res => { dispatch(updateChannelConversations(res.data.map(mapToChannelConversationItem).filter(x => x))) }
    ).catch(err => {

    })
}

export const LoadChannels = (dispatch: any, orgID: string) => {
    dispatch(LoadingChannels(true))
    ChannelService.getAllChannelAccounts
        (orgID)
        .then(res => {
            dispatch(updateChannels(res.data.map(mapToChannelGrid).sort((x: ChannelAccountModel, y: ChannelAccountModel) => new Date(x.UpdatedDate) <= new Date(y.UpdatedDate) ? 1 : -1)))
        }).catch(err => {

        })
}

export const UpdateChannelConversationHistory = (dispatch: any, channelConversationId: string, history: ChannelMessageItem[], updateLatest?: boolean) => {
    dispatch(updateChannelHistory({ channelConversationId, history, updateLatest }))
}

export const LoadChannelTemplates = (dispatch: any, orgID: string) => {
    ChannelConversationService.getAllChannelTemplates(orgID).then(res => {
        dispatch(updateChannelTemplates(res.data.filter(x => (x.channel === ChannelType.LIVE_CHAT || x.channel === ChannelType.GOOGLE_BUSINESS)).map(mapToChannelTemplateModel).sort((x: ChannelTemplates, y: ChannelTemplates) => new Date(x.UpdatedAt) <= new Date(y.UpdatedAt) ? 1 : -1)))
        dispatch(updateFaceboolTemplates(res.data.filter(x => x.channel === ChannelType.FACEBOOK).map(mapToFaceBookTemplateModel).sort((x: ChannelTemplates, y: ChannelTemplates) => new Date(x.UpdatedAt) <= new Date(y.UpdatedAt) ? 1 : -1)))
    }).catch(err => {

    })
}

export const LoadChannelUsers = (dispatch: any) => {
    const user = ChannelUserService.getCurrentUserFromLocalStorage()
    ChannelUserService.getAllUsers(user.OrganizationID).then(res => {
        dispatch(updateChannelUsers(res.data?.map(mapToChannelUser)))
    }
    )
}

export const LoadAllChannelUsers = (dispatch: any) => {
    ChannelService.getAllChannelUsers().then(res => {
        dispatch(updateAllChannelUsers(res.data?.map(x => mapToChannelUser(x))))
    }
    )
}

export const LoadAllChannelOrganizations = (dispatch: any) => {
    ChannelService.getAllChannelOrganisations().then(res => {
        dispatch(updateAllChannelOrganizations(res.data?.map(x => mapToChannelOrganization(x))))
    }
    )
}

export const UpdateActiveChannelsTypes = (dispatch: any) => {
    ProviderService.getChannels().then((res) => {
        dispatch(updateActiveChannelsTypes(res.data.filter(x => x.status === 1).map(x => x.id)))
    }).catch((err) => {

    })
}

export const LoadChannelContacts = (dispatch: any, OrganizationID: string) => {
    ChannelContactServices.getAllChannelContacts(OrganizationID).then(
        (res) => {
            dispatch(updateChannelContacts(res.data.map(x => mapToChannelContactItem(x))))
        }
    ).catch((err) => { })
}

export const UpdateWordpressDetails = (dispatch: any, data: { wordpressDomain: string, wordpress: boolean }) => {
    dispatch(updateWordPressDetails(data))

}