import { PlanType } from "../models/plans.model";
import { RootState } from "../stores/app-store";
export const loggedInUserroleId = (state: RootState) => state.userRoleId
export const conversations = (state: RootState) => state.Conversations
export const newConversationsCount = (state: RootState) => state.Conversations.filter(x => (!!x.GroupChat?.ID ? x.GroupChat?.UnReadCount : x.UnReadCount) > 0).length + state.ChannelConversations.filter(x => x.UnReadCount > 0).length
export const profile = (state: RootState) => state.Profile
export const isMobileDevice = (state: RootState) => state.IsMobileDevice
export const isTeamsApp = (state: RootState) => state.IsTeamsApp
export const normalTextingUsage = (state: RootState) => state.PlanMetrics.NormalTextingUsage
export const phoneNumbers = (state: RootState) => state.PhoneNumbers
export const usersAlertNotification = (state: RootState) => state.UserAlertNotifications
export const showingAlert = (state: RootState) => state.ShowingAlertsCount > 0
export const planMetrics = (state: RootState) => state.PlanMetrics
export const deviceWidth = (state: RootState) => state.DeviceWidth
export const deviceHeight = (state: RootState) => state.DeviceHeight
export const syncingContacts = (state: RootState) => state.SyncingContacts
export const alertNotification = (state: RootState) => state.AlertNotifications
export const loadingAlertNotifications = (state: RootState) => state.LoadingAlertsNotications
export const rulesKeywords = (state: RootState) => state.RulesKeywords
export const globalRules = (state: RootState) => state.GlobalRules
export const templates = (state: RootState) => state.Templates
export const organizations = (state: RootState) => state.Organizations
export const plans = (state: RootState) => state.Plans
export const features = (state: RootState) => state.Features
export const loadingRules = (state: RootState) => state.LoadingAutomationGlobalRules
export const allKeywords = (state: RootState) => state.AllKeywords
export const privateRules = (state: RootState) => state.PrivateRules
export const groups = (state: RootState) => state.Groups
export const IsMassGroupsDeletionInProgress = (state: RootState) => state.LoadedGroups
export const contacts = (state: RootState) => state.Contacts
export const getUnSubscribedContacts = (state: RootState) => state.GetUnSubscribedContacts
export const getBlockedContacts = (state: RootState) => state.GetBlockedContacts
export const groupContactsDeletionData = (state: RootState) => state.GroupContactsDeletionData
export const settings = (state: RootState) => state.Settings.Settings
export const isMassDeletionInProgress = (state: RootState) => state.IsMassContactsDeletionInProgress
export const users = (state: RootState) => state.Users
export const allUsers = (state: RootState) => state.AllUsers
export const typingData = (state: RootState) => state.TypingData
export const history = (state: RootState) => state.History
export const ContactTypes = (state: RootState) => state.ContactTypes
export const showSideMenu = (state: RootState) => state.ShowSideMenu
export const newMessageRecived = (state: RootState) => state.NewMessageRecived
export const secureFileLinks = (state: RootState) => state.SecureFileLinks
export const kafka = (state: RootState) => state.Kafka
export const providers = (state: RootState) => state.Providers
export const oadingProviders = (state: RootState) => state.LoadingProviders
export const planHistory = (state: RootState) => state.PlanHistory
export const roles = (state: RootState) => state.Roles
export const reports = (state: RootState) => state.Reports
export const allGlobalRules = (state: RootState) => state.AllGlobalRules
export const settingsObj = (state: RootState) => state.Settings
export const addons = (state: RootState) => state.Plans.filter(x => x.Type === PlanType.ADD_ON)
export const headerCount = (state: RootState) => state.HeaderCount
export const refreshHandler = (state: RootState) => state.RefreshHandler;
export const backHandler = (state: RootState) => state.BackHandler;
export const headerTitle = (state: RootState) => state.HeaderTitle;
export const toasts = (state: RootState) => state.Toasts;

export const loadingConversations = (state: RootState) => state.LoadingConversations;
export const loadingHistory = (state: RootState) => state.LoadingHistory;
export const loadingContacts = (state: RootState) => state.LoadingContacts;
export const loadingContactTypes = (state: RootState) => state.LoadingContactTypes;
export const loadingUsers = (state: RootState) => state.LoadingUsers;
export const loadingGroups = (state: RootState) => state.LoadingGroups;
export const loadingOrganizations = (state: RootState) => state.LoadingOrganizations;
export const loadingSettings = (state: RootState) => state.LoadingSettings;
export const loadingProfile = (state: RootState) => state.LoadingProfile;
export const loadingFeatures = (state: RootState) => state.LoadingFeatures;
export const loadingRoles = (state: RootState) => state.LoadingRoles;
export const loadingPhoneNumbers = (state: RootState) => state.LoadingPhoneNumbers;
export const loadingPlans = (state: RootState) => state.LoadingPlans;
export const loadingProviders = (state: RootState) => state.LoadingProviders;
export const loadingOrganizationDetails = (state: RootState) => state.LoadingOrganizationDetails;
export const loadingAlertsNotications = (state: RootState) => state.LoadingAlertsNotications;
export const loadingAutoAlertKeywords = (state: RootState) => state.LoadingAutoAlertKeywords;
export const loadingTemplates = (state: RootState) => state.LoadingTemplates;
export const loadingAutomationPrivateRules = (state: RootState) => state.LoadingAutomationPrivateRules;
export const loadingAutomationGlobalRules = (state: RootState) => state.LoadingAutomationGlobalRules;
export const loadingReports = (state: RootState) => state.LoadingReports;
export const loadingKafka = (state: RootState) => state.LoadingKafka;
export const secureFiles = (state: RootState) => state.SecureFiles;
export const loadingSecureFiles = (state: RootState) => state.LoadingSecureFiles;
export const loadingSecureFileLinks = (state: RootState) => state.LoadingSecureFileLinks;
export const allContacts = (state: RootState) => state.AllContacts;

export const loadedConversations = (state: RootState) => state.LoadedConversations;
export const loadedHistory = (state: RootState) => state.LoadedHistory;
export const loadedContacts = (state: RootState) => state.LoadedContacts;
export const loadedContactTypes = (state: RootState) => state.LoadedContactTypes;
export const loadedUsers = (state: RootState) => state.LoadedUsers;
export const loadedGroups = (state: RootState) => state.LoadedGroups;
export const loadedOrganizations = (state: RootState) => state.LoadedOrganizations;
export const loadedSettings = (state: RootState) => state.LoadedSettings;
export const loadedProfile = (state: RootState) => state.LoadedProfile;
export const loadedFeatures = (state: RootState) => state.LoadedFeatures;
export const loadedRoles = (state: RootState) => state.LoadedRoles;
export const loadedPhoneNumbers = (state: RootState) => state.LoadedPhoneNumbers;
export const loadedPlans = (state: RootState) => state.LoadedPlans;
export const loadedProviders = (state: RootState) => state.LoadedProviders;
export const loadedOrganizationDetails = (state: RootState) => state.LoadedOrganizationDetails;

export const isMassContactsDeletionInProgress = (state: RootState) => state.IsMassContactsDeletionInProgress;
export const isMassGroupsDeletionInProgress = (state: RootState) => state.IsMassGroupsDeletionInProgress;
export const handleMassContactsActionEvent = (state: RootState) => state.HandleMassContactsActionEvent;
export const handleMassGroupsActionEvent = (state: RootState) => state.HandleMassGroupsActionEvent;
export const IsMessageLogDownloadReportMsg = (state: RootState) => state.IsMessageLogDownloadReportMsg;
export const ExportMessageLogs = (state: RootState) => state.ExportMessageLogs;
export const WebNotificationText = (state: RootState) => state.WebNotificationText;
export const OrganizationName = (state: RootState) => state.OrganizationName;



//Connect Channels
export const ChannelConversations = (state: RootState) => state.ChannelConversations;
export const LoadingConnect = (state: RootState) => state.LoadingConnect;
export const LoadingChannels = (state: RootState) => state.LoadingChannels;
export const Channels = (state: RootState) => state.Channels;
export const ChannelTemplates = (state: RootState) => state.ChannelTemplates;
export const FacebookTemplates = (state: RootState) => state.FacebookTemplates;
export const ChannelHistory = (state: RootState) => state.ChannelHistory;
export const ChannelUsers = (state: RootState) => state.ChannelUsers;
export const ActiveChannelsTypes = (state: RootState) => state.ActiveChannelsTypes;
export const ChannelAllUsers = (state: RootState) => state.ChannelAllUsers;
export const ChannelAllOrganizations = (state: RootState) => state.ChannelAllOrganizations;
export const IsWordpress = (state: RootState) => state.IsWordPress;
export const WordPressDomain = (state: RootState) => state.WordPressDomain;

