export const WebRoutes = {
    SIGN_IN: "/sign-in",
    SIGN_UP: "/sign-up",
    DASHBOARD: "/dashboard",
    PLANS: "/plans",
    RECHARGE: "/recharge",
    USERS: "/users",
    CONVERSATIONS: "/conversations",
    CONVERSATION_HISTORY: "/conversations/:id",
    SCHEDULED_MESSAGES: "/scheduled-messages",
    CONTACTS: "/contacts",
    IMPORT_CONTACTS: "/contacts/import-contacts",
    IMPORT_CONTACTS_EXCEL: "/contacts/import-contacts/excel",
    IMPORT_CONTACTS_OUTLOOK: "/contacts/import-contacts/outlook",
    GROUPS: "/groups",
    PROFILE: "/profile",
    SETTINGS: "/settings",
    BLOCKED: "/settings/blocked",
    UNSUBSCRIBE: "/settings/unsubscribe",
    SIGNATURE: "/settings/signature",
    TIMEZONE: "/settings/timezone",
    SCHEDULED_REPORT: "/settings/scheduledreport",
    CHANGE_PASSWORD: "/change-password",
    ADD_PHONE_NUMBER: "/add-phone-number",
    PAYMENT_STATUS: "/payment-status",
    REHOST: "/rehost",
    FORGOT_PASSWORD: "/forgot-password",
    RESET_PASSWORD_NEW: "/reset-password",
    TWO_FACTOR_AUTHENTICATION: "/2fa",
    ORGANIZATIONS: "/organizations",
    PHONE_NUMBERS: "/phone-numbers",
    TIERS: "/sysadmin-plans",
    ADD_PLAN: "/sysadmin-plans/:PlanID",
    FEATURES: "/features",
    RESET_PASSWORD_OLD: "/ResetPassword",
    HOME_PATH: "/",
    TRANSFERS: "/transfers",
    TRANSFER_LOGS: "/transfers/logs",
    ALERTS: "/alerts",
    TEMPLATES: "/templates",
    ADD_WHATSAPP_TEMPLATES_SETUP: "/templates/whatsapp/add-template/set-up",
    ADD_WHATSAPP_TEMPLATES_EDIT_AND_PREVIEW: "/templates/whatsapp/add-template/edit-preview/:template-type",
    ADD_WHATSAPP_TEMPLATES_SUCCESS_SCREEN:"/templates/whatsapp/add-template/success",
    TEMPLATES_EVERY_ONE: "/templates/public",
    AUTOMATION_RULES: "/automation/rules",
    AUTOMATION_KEYWORD: "/automation/keyword",
    AUTOMATION_LOGS: "/automation/logs",
    CHANNELS: "/all-channels",
    ADD_CHANNELS: "/all-channels/add-channel",
    AUTOMATION_RULES_GLOBAL: "/automation/rules/global-rules",
    REPORTS: "/reports/schedule-broadcast-logs",
    ADD_CHANNELS_FACEBOOK: "/channels/add-channel/1",
    ADD_CHANNELS_INSTAGRAM: "/channels/add-channel/2",
    ADD_CHANNELS_WHATSAPP: "/channels/add-channel/3",
    ADD_CHANNELS_BY_TYPE: "/channels/add-channel/:Type",
    ADD_CHANNELS_LIVE_CHAT: "/channels/add-channel/livechat/4",
    REPORT_VIEW: "/reports/report-view",
    PLANS_PLAN_HISTORY: "/plans/:phoneID/plan-history",
    PHONE_NUMBERS_PLAN_HISTORY: "/phone-numbers/:phoneID/plan-history",
    SWITCH_PHONE_NUMBER: "/switch-number",
    KAFKA_CLUSTER: "/kafka-cluster",
    GROUPDELETIONINFO: "/groups/group-contacts-deletion-info",
    LIVE_CHAT: "/falkon-live-chat/:channelId/:isMobile",
    SHARE_TO_CHAT_LINK: "/:channelId/share-to-chat-link",
    BUSSINESS_HOURS: "/settings/bussiness-hours",
    SSO_ERROR_PAGE: "/sso-error/:state",
    SECURE_FILE: "/secure-files",
    SECURE_FILE_REDIRECT: "/securedFile",
    CreditLog: "/reports/creditLog",
    MESSAGE_LOGS: "/reports/message-logs",
    ExportReport: "/reports/exportreport",
    LIVE_CHAT_CONVERSATION: "/live-chat/conversation/:channelId/:deviceId/:userId/:title",
    LIVE_CHAT_POP_UP: "/live-chat/:id/:title/",
    CHANNELS_SIGN_IN: "/channels/sign-in",
    CHANNELS_SIGN_UP: "/channels/sign-up",
    CHANNELS_SETPASSWORD: "/channels/set-password",
    CHANNELS_FORGET_PASSWORD: "/channels/forget-password",
    CHANNELS_DASHBOARD: "/channels/dashboard",
    CHANNELS_PLANS: "/channels/plans",
    CHANNELS_LOGIN_REDIRECT: "/channels/:login/redirect",
    CHANNELS_MYTEAM: "/channels/my-team",
    CHANNELS_INVIRE_USERS: "/channels/users/invite",
    CHANNELS_ORGANISATIONS: "/channels/organisations",
    CHANNELS_USERS: "/channels/users",
    SYS_ADMIN_CHANNELS: "/sys-admin/channels",
    CHANNELS_USER_NOT_FOUND: "/channels/user-not-found/:deletedBy",
}

export const TeamsRoutes = {
    SIGN_IN: "/teams/sign-in",
    SIGN_UP: "/teams/sign-up",
    DASHBOARD: "/teams/dashboard",
    PLANS: "/teams/plans",
    RECHARGE: "/teams/recharge",
    USERS: "/teams/users",
    CONVERSATIONS: "/teams/conversations",
    CONVERSATION_HISTORY: "/teams/conversations/:id",
    SCHEDULED_MESSAGES: "/teams/scheduled-messages",
    CONTACTS: "/teams/contacts",
    IMPORT_CONTACTS: "/teams/contacts/import-contacts",
    IMPORT_CONTACTS_EXCEL: "/teams/contacts/import-contacts/excel",
    IMPORT_CONTACTS_OUTLOOK: "/teams/contacts/import-contacts/outlook",
    GROUPS: "/teams/groups",
    PROFILE: "/teams/profile",
    SETTINGS: "/teams/settings",
    BLOCKED: "/teams/settings/blocked",
    UNSUBSCRIBE: "/teams/settings/unsubscribe",
    SIGNATURE: "/teams/settings/signature",
    TIMEZONE: "/teams/settings/timezone",
    SCHEDULED_REPORT: "/teams/settings/scheduledreport",
    CHANGE_PASSWORD: "/teams/change-password",
    ADD_PHONE_NUMBER: "/teams/add-phone-number",
    PAYMENT_STATUS: "/teams/payment-status",
    REHOST: "/teams/rehost",
    FORGOT_PASSWORD: "/teams/forgot-password",
    RESET_PASSWORD_NEW: "/teams/reset-password",
    TWO_FACTOR_AUTHENTICATION: "/teams/2fa",
    ORGANIZATIONS: "/teams/organizations",
    PHONE_NUMBERS: "/teams/phone-numbers",
    TIERS: "/teams/sysadmin-plans",
    ADD_PLAN: "/teams/sysadmin-plans/:PlanID",
    FEATURES: "/teams/features",
    RESET_PASSWORD_OLD: "/teams/ResetPassword",
    ALERTS: "/teams/alerts",
    AUTOMATION_RULES: "/teams/automation/rules",
    AUTOMATION_KEYWORD: "/teams/automation/keyword",
    AUTOMATION_LOGS: "/teams/automation/logs",
    HOME_PATH: "/teams",
    TRANSFERS: "/teams/transfers",
    TRANSFER_LOGS: "/teams/transfers/logs",
    TEMPLATES: "/teams/templates",
    ADD_WHATSAPP_TEMPLATES_SETUP: "/teams/templates/whatsapp/add-template/set-up",
    ADD_WHATSAPP_TEMPLATES_EDIT_AND_PREVIEW: "/teams/templates/whatsapp/add-template/edit-preview/:template-type",
    ADD_WHATSAPP_TEMPLATES_SUCCESS_SCREEN:"/teams/templates/whatsapp/add-template/success",
    CHANNELS: "/teams/all-channels",
    TEMPLATES_EVERY_ONE: "/teams/templates/public",
    ADD_CHANNELS: "/teams/channels/add-channel",
    AUTOMATION_RULES_GLOBAL: "/teams/automation/rules/global-rules",
    REPORTS: "/teams/reports/schedule-broadcast-logs",
    ADD_CHANNELS_FACEBOOK: "/teams/channels/add-channel/1",
    ADD_CHANNELS_INSTAGRAM: "/teams/channels/add-channel/2",
    ADD_CHANNELS_WHATSAPP: "/teams/channels/add-channel/3",
    ADD_CHANNELS_BY_TYPE: "/teams/channels/add-channel/:Type",
    ADD_CHANNELS_LIVE_CHAT: "/teams/channels/add-channel/livechat/4",
    REPORT_VIEW: "/teams/reports/report-view",
    PLANS_PLAN_HISTORY: "/teams/plans/:phoneID/plan-history",
    PHONE_NUMBERS_PLAN_HISTORY: "/teams/phone-numbers/:phoneID/plan-history",
    ADD_CHANNEL: "/teams/channels/add-channel/:type",
    SWITCH_PHONE_NUMBER: "/teams/switch-number",
    KAFKA_CLUSTER: "/teams/kafka-cluster",
    GROUPDELETIONINFO: "/teams/groups/group-contacts-deletion-info",
    TEAMS_LOGIN_PAGE: "/teams/tab-auth/simple-start",
    LIVE_CHAT: "/teams/falkon-live-chat/:channelId/:isMobile",
    SHARE_TO_CHAT_LINK: "/teams/:channelId/share-to-chat-link",
    BUSSINESS_HOURS: "/teams/settings/bussiness-hours",
    TEAMS_LOGIN_PAGE_SSO: "/teams/sso-teams-start",
    TEAMS_CONCENTPOPUP_END_SSO: "/teams/sso-teams-end",
    SSO_ERROR_PAGE: "/teams/sso-error/:state",
    LIVE_CHAT_CONVERSATION: "/teams/live-chat/conversation",
    LIVE_CHAT_POP_UP: "/teams/live-chat/:id/:title",
    TEAMS_CONCENTPOPUP_END: "/teams/tab-auth/simple-end",
    SECURE_FILE: "/teams/secure-files",
    SECURE_FILE_REDIRECT: "/teams/securedFile",
    CreditLog: "/teams/reports/creditLog",
    ExportReport: "/teams/reports/exportreport",
    MESSAGE_LOGS: "/teams/reports/message-logs",
    CHANNELS_SIGN_IN: "/teams/channels/sign-in",
    CHANNELS_SIGN_UP: "/teams/channels/sign-up",
    CHANNELS_SETPASSWORD: "/temas/channels/set-password",
    CHANNELS_FORGET_PASSWORD: "/teams/channels/forget-password",
    CHANNELS_DASHBOARD: "/teams/channels/dashboard",
    CHANNELS_PLANS: "/teams/channels/plans",
    CHANNELS_LOGIN_REDIRECT: "/teams/channels/:login/redirect",
    CHANNELS_MYTEAM: "/teams/channels/my-team",
    CHANNELS_INVIRE_USERS: "/teams/channels/users/invite",
    CHANNELS_ORGANISATIONS: "/teams/channels/organisations",
    CHANNELS_USERS: "/teams/channels/users",
    SYS_ADMIN_CHANNELS: "/teams/sys-admin/channels",
    CHANNELS_USER_NOT_FOUND: "/teams/channels/user-not-found/:deletedBy",
    TEAMS_POPUP_CONTAINER: "/teams/popup/:type",
}

export const AppRoutes = window.location.pathname.includes('teams') ? TeamsRoutes : WebRoutes;
